import React from "react"
import PropTypes from "prop-types"
import styles from "src/styles/components/tags-bar.module.scss"
import { Link } from "gatsby"
import cx from "classnames"
import blogJson from "content/data/static-pages/blog.json"
import tagSlug from "src/helpers/tag-slug"

const TagsBar = ({ tag, allTags }) => {
  return (
    <div className={styles.root}>
      <div className={styles.contentRoot}>
        <Link
          to="/blog"
          className={cx(styles.link, { [styles.selected]: tag === undefined })}
        >
          {blogJson.de.all_posts}
        </Link>
        {allTags.map(t => (
          <Link
            to={`/blog/tagged/${tagSlug(t)}`}
            key={t}
            className={cx(styles.link, { [styles.selected]: t === tag })}
          >
            {t}
          </Link>
        ))}
      </div>
    </div>
  )
}

TagsBar.propTypes = {
  tag: PropTypes.string,
  allTags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TagsBar
