import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import cardStyles from "src/styles/components/card.module.scss"
import { Grid } from "@material-ui/core"
import styles from "src/styles/components/blog-post-preview.module.scss"
import cx from "classnames"

export default function BlogPostPreview({ post, slug, excerpt, fullWidth }) {
  const { title, date, hero_image } = post
  return (
    <Grid item xs={12} sm={6} md={4} style={{ alignSelf: "stretch" }}>
      <div
        className={cx(cardStyles.cardContainer, styles.root, {
          [styles.fullWidth]: fullWidth,
        })}
      >
        <Link to={`/blog/${slug}`}>
          <Img
            className={styles.image}
            fluid={hero_image.childImageSharp.fluid}
          />
          <div className={styles.textContainer}>
            <p className="small">{date}</p>
            <h3 title={title} className={styles.title}>
              {title}
            </h3>
            <p className="small">{excerpt}</p>
          </div>
        </Link>
      </div>
    </Grid>
  )
}

BlogPostPreview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    hero_image: PropTypes.object,
  }),
  slug: PropTypes.string,
  excerpt: PropTypes.string,
  fullWidth: PropTypes.bool,
}

BlogPostPreview.defaultProps = {
  fullWidth: true,
}
