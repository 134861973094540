import React from "react"
import PropTypes from "prop-types"
import BlogList from "src/components/blog-list"
import Navbar from "src/components/navbar"
import Layout from "src/components/layout"
import { graphql, Link } from "gatsby"
import blogJson from "content/data/static-pages/blog.json"
import NewsletterSubscriptionForm from "src/components/newsletter-subscription-form"
import Section from "src/components/section"
import { Grid, Box } from "@material-ui/core"
import navbarStyles from "src/styles/components/navbar.module.scss"

export default function BlogPage(props) {
  const list = props.data.allMarkdownRemark.edges
  const { currentPage, numPages, tag, allTags } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  const {
    de: { footer_text, footer_description, cta_text, cta_link },
  } = blogJson

  return (
    <Layout>
      <Navbar className={navbarStyles.green} />
      <BlogList list={list} tag={tag} allTags={allTags} />
      <Box py={4}>
        <Grid container justify="center">
          <Grid item>
            {!isFirst && (
              <Box px={2}>
                <Link to={`/blog/${prevPage}`} rel="prev">
                  ← Vorherige Seite
                </Link>
              </Box>
            )}
          </Grid>
          <Grid item>
            {!isLast && (
              <Box px={2}>
                <Link to={`/blog/${nextPage}`} rel="next">
                  Nächste Seite →
                </Link>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <NewsletterSubscriptionForm />
      <Section
        section={{
          title: footer_text,
          description: footer_description,
          cta_text,
          cta_link,
        }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query getBlogData($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        frontmatter: { wip: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            author
            title
            hero_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 200)
          fields {
            slug
          }
        }
      }
    }
  }
`

BlogPage.propTypes = {
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
    tag: PropTypes.string,
    allTags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.arrayOf(PropTypes.object),
  }),
}
