import React from "react"
import PropTypes from "prop-types"
import sectionStyles from "src/styles/components/section.module.scss"
import BlogListHeader from "./blog-list-header"
import BlogPostPreview from "./blog-post-preview"
import { Grid } from "@material-ui/core"
import cx from "classnames"
import SEO from "./seo"
import TagsBar from "./tags-bar"

export default function BlogList(props) {
  const blogData = props.list
  const { tag, allTags } = props

  return (
    <>
      <SEO
        title="Blog"
        image={
          blogData[0].node.frontmatter.hero_image.childImageSharp.fluid.src
        }
      />
      <BlogListHeader
        post={blogData[0].node.frontmatter}
        slug={blogData[0].node.fields.slug}
        excerpt={blogData[0].node.excerpt}
      />
      <TagsBar tag={tag} allTags={allTags} />
      <Grid
        container
        className={cx(sectionStyles.root, sectionStyles.boundedWidth)}
      >
        {blogData.map(post => (
          <BlogPostPreview
            key={post.node.fields.slug}
            post={post.node.frontmatter}
            slug={post.node.fields.slug}
            excerpt={post.node.excerpt}
          />
        ))}
      </Grid>
    </>
  )
}

BlogList.propTypes = {
  list: PropTypes.array.isRequired,
  tag: PropTypes.string,
  allTags: PropTypes.arrayOf(PropTypes.string),
}
