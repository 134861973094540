import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { newsletterTextB2B, newsletterTextB2C } from "content/data/info.json"
import "src/styles/components/newsletter-subscription-form.scss"

const FORM_ACTION_B2C =
  "https://mindance.us16.list-manage.com/subscribe/post?u=f3a0839b731d7e6b58dd17d19&amp;id=9a893db920"
const FORM_ACTION_B2B =
  "https://mindance.us16.list-manage.com/subscribe/post?u=f3a0839b731d7e6b58dd17d19&amp;id=43e189c4f5"

const NewsletterSubscriptionForm = ({ type }) => {
  const action = type === "b2b" ? FORM_ACTION_B2B : FORM_ACTION_B2C
  const text = type === "b2b" ? newsletterTextB2B : newsletterTextB2C
  return (
    <>
      <Helmet>
        <link
          href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
          rel="stylesheet"
          type="text/css"
        ></link>
      </Helmet>
      <div id="mc-signup">
        <div id="form-container">
          <div id="form-background" />
          <form
            action={action}
            method="post"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <label htmlFor="mce-EMAIL">{text}</label>
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="E-Mail Adresse"
              required
            />
            <div
              style={{
                position: "absolute",
                left: -5000,
                ariaHidden: "true",
              }}
            >
              <input
                type="text"
                name="b_f3a0839b731d7e6b58dd17d19_9a893db920"
                tabIndex="-1"
                value=""
              />
            </div>
            <input
              type="submit"
              value="Abonnieren"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
            />
          </form>
        </div>
      </div>
    </>
  )
}

NewsletterSubscriptionForm.propTypes = {
  type: PropTypes.oneOf(["b2b", "b2c"]),
}

NewsletterSubscriptionForm.defaultProps = {
  type: "b2c",
}

export default NewsletterSubscriptionForm
