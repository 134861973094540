import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styles from "src/styles/components/blog-list-header.module.scss"
import sectionStyles from "src/styles/components/section.module.scss"
import cardStyles from "src/styles/components/card.module.scss"
import { Grid } from "@material-ui/core"
import blogJson from "content/data/static-pages/blog.json"

export default function BlogListHeader({ post, slug, excerpt }) {
  const { title, date, hero_image } = post
  const {
    de: { read_more_text },
  } = blogJson
  const link = `/blog/${slug}`

  return (
    <Grid container spacing={4} className={cx(sectionStyles.root, styles.root)}>
      <Grid className={sectionStyles.boundedWidth} container xs={12}>
        <Grid className={styles.titleContainer} item sm={12} md>
          <p className="small gray">{date}</p>
          <Link to={link}>
            <h1>{title}</h1>
          </Link>
          <p>{excerpt}</p>
          <Link to={link}>
            <button>{read_more_text}</button>
          </Link>
        </Grid>
        <Grid className={styles.imageContainer} item sm={12} md>
          <Link to={link}>
            <Img
              className={cx(cardStyles.cardContainer, styles.image)}
              fluid={hero_image.childImageSharp.fluid}
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

BlogListHeader.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    hero_image: PropTypes.object,
  }),
  slug: PropTypes.string,
  excerpt: PropTypes.string,
}
